.Bar-colorTransparent {
    color: inherit;
    box-shadow: none;
    background-color: transparent;
}

.Bar-positionFixed {
    top: 0;
    left: auto;
    right: auto;
    position: fixed;
    max-width: 600px;
    width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
}

.Page-root {
    color: #0f2930;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.NavbarToolbar {
    display: flex;
    justify-content: space-between;
}

.Toolbar-root {
    display: flex;
    position: relative;
    align-items: center;
}

.Toolbar-regular {
    min-height: 56px;
}

.Toolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
}

.IconButton-root {
    flex: 0 0 auto;
    overflow: visible;
    text-align: center;
    border-radius: 50%;
}

.ButtonBase-root {
    margin: 0;
    outline: 0;
    position: relative;
    align-items: center;
    justify-content: center;
}

.NavbarButton {
    color: #fff !important;
    background-color: rgba(0,0,0,.35) !important;
    box-shadow: none;
}

.ProductName {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Font-body2 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
}

.CartButton-root {
    color: #0f2930;
    padding: 6px 16px;
    font-size: 1rem;
    min-width: 64px;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 8px;
    text-transform: none;
}

.CartButton-contained {
    color: rgba(255, 255, 255, 0.87);
    box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
    background-color: #5ACF8F;
}

.CartButtonBase-root {
    font-size: 1rem;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -webkit-appearance: none;
}

.AddToCartButton {
    position: fixed !important;
    padding: 8px 16px !important;
    bottom: 10px;
    left: 8px;
    width: calc(100% - 16px) !important;
}

.AddToCartButton:disabled {
    background-color: #B1EDCC;
    box-shadow: none;
}

.nav {
    padding: 0rem 0.5rem !important;
}

@media(min-width: 600px) {
    /* .Toolbar-regular {
        min-height: 64px;
    } */

    .Toolbar-gutters {
        padding-left: 24px;
        padding-right: 24px;
    }
}