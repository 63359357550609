/* a {
  color: #62BD8A !important;
} */

.Header_shop {
  padding: 0.7rem 1rem;
  display: flex;
  background-color: #FFFFFF;
}

.Logo {
  /* margin-top: -20px; */
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(52,58,67,.1),0 5px 10px rgba(52,58,67,.08),0 15px 35px rgba(52,58,67,.08);
  background-color: #fff;
}

.Logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 0;
}

.MainContainer-root {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.MainContainer-disableGutters {
  padding-left: 0;
  padding-right: 0;
}

.MainContainer {
  max-width: 600px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.BottomButton {
  position: fixed !important;
  padding: 8px 16px !important;
  bottom: 10px;
  left: 8px;
  width: calc(100% - 16px) !important;
}

.BottomButton:disabled {
  background-color: #B1EDCC;
  box-shadow: none;
}

.mb-6 {
  margin-bottom: 5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Added by WNAZAR */
.Grid_wrapper {
  margin-bottom: 4rem;
  overflow: hidden;
}

.Grid_list {
  margin: 0.7rem .5rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  -webkit-animation-duration: .25s;
  animation-duration: .25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.Link-root {
  font-weight: 700;
}

.Font-colorPrimary {
  color: #62BD8A;
}

.Font-root {
  margin: 0;
}

.GridItem_item {
  width: 50%;
}

.GridItem_card {
  margin: 0 .5rem 1rem;
  overflow: hidden;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 0 2px rgba(52,58,67,.1),0 2px 5px rgba(52,58,67,.08),0 5px 15px rgba(52,58,67,.08);
  padding-bottom: .5rem;
}

.GridItem_image {
  position: relative;
  margin: 0;
  line-height: 0;
  border-bottom: 1px solid #f3f1f2;
}

.GridImg {
  object-fit: cover;
  width: 100%;
  height: 165px;
}

.Font-displayBlock {
  display: block;
}

.Font-overline {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.66;
}

.GridItemCategory_caption {
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  width: calc(100% - 0px);
  color: rgba(15,41,48,.75) !important;
  line-height: 1.5 !important;
  font-weight: 600;
}

.GridItem_caption {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
  text-align: left;
  margin: .5rem .5rem 0 !important;
  color: rgba(15,41,48,.75) !important;
  line-height: 1.5 !important;
}

.GridItem_priceContainer {
  text-align: left;
  padding: 0 .5rem !important;
}

.Font-caption {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.66;
}

.GridItem_price {
  display: inline-block;
  font-size: 1rem !important;
}

.CartBadge-root {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
}

.ButtonCartBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  -moz-user-select: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.ButtonCart-root {
  flex: 0 0 auto !important;
  color: rgba(0, 0, 0, 0.54) !important;
  padding: 8px !important;
  overflow: visible !important;
  font-size: 1rem !important;
  text-align: center !important;
  border-radius: 50% !important;
  background-color: #5ACF8F;
}

.Badge-anchorTopRight {
  top: 14%;
  right: 0%;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}

.CartBadge-badge {
  height: 18px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-weight: 400;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}

.pb-5 {
  padding-bottom: 4rem !important;
}

@media(min-width: 600px) {
  .GridItem_item {
    width: calc(100% / 3);
  }

  .GridImg {
    object-fit: cover;
    width: 100%;
    height: 180px;
  }
}

.btn {
  border-radius: 10px !important;
}

.btn-outline-danger {
  color: #FF5757;
  border-color: #FF5757;
}

.btn-outline-danger:hover {
  background-color: #FF5757;
  border-color: #FF5757;
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loader-container .loader {
  top: 40%;
  left: 48%;
  z-index: 1000;
  position: absolute;
}

.Font-secondary-color {
  color: #737373;
}

.badge-danger {
  background-color: #FF5757 !important;
}

/* react-horizontal-scrolling-menu */
.menu-item {
  padding: 0 20px;
  margin: 5px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item.active {
  border: 1px rgba(15,41,48,.75) solid;
}

.scroll-menu-arrow {
  padding: 0px 10px;
  cursor: pointer;
}

.btn-secondary {
  background-color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
}

.btn-secondary.active {
  background-color: #545b62 !important;
  border-color: #545b62 !important;
}

.btn-group > .btn.active {
  z-index: 0 !important;
}

.fix-new {
  font-size: 10px !important; /*we can easly set the credit card size*/
}

.card-new {
  margin: auto;
  /* background: linear-gradient(to right, rgb(4, 139, 67), rgb(90, 207, 143)) !important; */
  background: url('./components/card_pattern.jpg') !important;
  border: 0px !important;
}

.card-error {
  background-color: #f79090 !important;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: 5px !important;
}