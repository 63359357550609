.FormControl-fullWidth {
    width: 100%;
}

.FormControl-marginNormal {
    margin-top: 16px;
    margin-bottom: 8px;
}

.FormControl-root {
    border: 0;
    /* margin: 0;
        margin-top: 0px;
        margin-bottom: 0px; */
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}

.OutlinedInput-root {
    position: relative;
    box-shadow: 0 0 2px rgba(52,58,67,.1),0 1px 2px rgba(52,58,67,.08),0 1px 4px rgba(52,58,67,.08);
    border-radius: 8px;
}

.InputBase-fullWidth {
    width: 100%;
}

.InputBase-root {
    color: #0f2930;
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-weight: 400;
    line-height: 1.1876em;
}

.OutlinedInput-input {
    padding: 14.5px 14px !important;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08) !important;
    border-radius: 8px;
}

.InputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: #ffffff;
    box-sizing: content-box;
    letter-spacing: inherit;
    animation-duration: 10ms;
}

.InputBase-textArea {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: #ffffff;
    box-sizing: content-box;
    letter-spacing: inherit;
    animation-duration: 10ms;
}

.Button-fullWidth {
    padding: 8px 16px !important;
    margin-bottom: 10px;
    margin-left: 0px;
    width: 100% !important;
}

.Button-fullWidth:disabled {
    background-color: #B1EDCC;
    box-shadow: none;
}

.react-tel-input {
    width: 100% !important;
    font-family: "Poppins", sans-serif !important
}

.react-tel-input .form-control {
    border: 0px solid #CACACA !important;
    width: 100% !important;
    padding: 23.5px 14px 23.5px 60px !important;
    box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08) !important
}

.react-tel-input .country-list .search-box {
    width: 265px !important;
}

.react-tel-input .country-list .country {
    font-size: 12px !important;
}

.BottomDiv {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
}