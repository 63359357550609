.Cart_cardList {
    margin-top: 8px;
    margin-bottom: 16px;
}

.Card-root {
    overflow: hidden;
    color: #0f2930;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ffffff;
}

.Card-elevation {
    box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08);
}

.Card-rounded {
    border: none;
    /* box-shadow: 0px 0px 1px rgba(52,58,67,0.75); */
    border-radius: 8px;
}

.Cart_card {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 8px;
    background: #ffffff;
    background-color: rgb(255, 255, 255);
    /* box-shadow: 8px 8px 16px #ddd,-8px -8px 16px #fff; */
}

.Grid-spacing-xs-1 {
    width: calc(100% + 8px);
    /* margin: -4px; */
}

.Grid-wrap-xs-nowrap {
    flex-wrap: nowrap;
}

.Grid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.Cart_productContainer {
    display: flex;
    flex-direction: column;
}

.Cart_productImage {
    margin: 0;
    display: block;
    max-height: 96px;
    max-width: 96px;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    border-radius: 8px;
    background: #FFFFFF;
}

.Cart_productDetailsWrapper {
    padding-left: 8px;
    width: 100%;
}

.Cart_productDetailFont {
    margin-bottom: 2px;
    line-height: 1.2;
    font-weight: 300;
    color: #737373;
}

.Cart_removeProduct {
    position: absolute !important;
    top: 40%;
    right: 12px;
    box-shadow: 2px 2px 4px #d0d0d0,-2px -2px 4px #fff;
}

.Cart_quantityButton {
    height: 25px;
    width: 25px;
    padding: 0px !important;
    box-shadow: 2px 2px 4px #d0d0d0,-2px -2px 4px #fff;
}

.CheckoutButton {
    padding: 8px 16px !important;
    margin-bottom: 10px;
    margin-left: 8px;
    width: calc(100% - 16px) !important;
}

.CheckoutButton:disabled {
    background-color: #B1EDCC;
    box-shadow: none;
}

.BottomDiv {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
}

@media(min-width: 600px) {
    
}